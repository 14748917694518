<template>
    <Header/>
        <Banner name="Register" message="Sign up" />
        <!-- Register & Login Start -->
        <div class="section section-padding">
            <div class="container">

                <!-- Register & Login Wrapper Start -->
                <div class="register-login-wrapper">
                    <div class="row align-items-center">
                        <div class="col-lg-6">

                            <!-- Register & Login Images Start -->
                            <div class="register-login-images">
                                <div class="shape-1">
                                    <img src="@/assets/images/shape/shape-26.png" alt="Shape">
                                </div>


                                <div class="images">
                                    <img src="@/assets/images/register-login.png" alt="Register Login">
                                </div>
                            </div>
                            <!-- Register & Login Images End -->

                        </div>
                        <div class="col-lg-6">

                            <!-- Register & Login Form Start -->
                            <div class="register-login-forms mt-3">
                                <h3 class="title">Registration <span>Now</span></h3>

                                <div class="form-wrapper">
                                    <form action="#">
                                        <div class="row">
                                            <!-- Single Form Start -->
                                            <div class="single-form col-md-6">
                                                <!-- <label for="first_name">First Name</label> -->
                                                <input type="text" placeholder="First Name" v-model="details.first_name">
                                            </div>
                                            <div class="single-form col-md-6">
                                                <input type="text" placeholder="Last Name" v-model="details.last_name">
                                            </div>

                                            <div class="single-form col-md-6">
                                                <input type="email" placeholder="Email" v-model="details.email">
                                            </div>

                                            <div class="single-form col-md-6">
                                                <input type="tel" pattern="[0]{1}[0-9]{10}" placeholder="Phone Number" v-model="details.phone_number">
                                            </div>

                                            <div class="single-form col-md-6 ">
                                                <input type="password" placeholder="Password" v-model="details.password">
                                            </div>

                                            <div class="single-form col-md-6 ">
                                                <input type="password" placeholder="Confirm Password" v-model="details.password_confirmation">
                                            </div>
                                            <div class="single-form col-md-12">
                                                <input type="checkbox" name="has_sponsor" id="has_sponsor" @change="toggleSponsorStatus" :checked="has_sponsor">
                                                Are you being sponsored by an Organisation/Brand?
                                            </div>
                                            <div class="single-form col-md-12" v-show="has_sponsor">
                                                <select name="sponsor" id="sponsor" class="single-select-form" @change="selectOption($event)">
                                                    <option value="">---Select Sponsor---</option>
                                                    <option value="BACWWAN">BACWWAN</option>
                                                    <option value="ISHK">ISHK</option>
                                                </select>
                                            </div>

                                        </div>
                                        <!-- Single Form End -->
                                        <!-- Single Form Start -->
                                        <div class="single-form">
                                            <button type="button" class="btn btn-primary btn-hover-dark w-100 mb-3" @click="signup">
                                                {{ loading ? 'Sending...' : 'Create an account' }}
                                            </button>
                                            <router-link :to="{name:'Login'}" class="btn btn-secondary btn-outline w-100">
                                               Login
                                            </router-link>
                                        </div>
                                        <!-- Single Form End -->
                                    </form>
                                </div>
                            </div>
                            <!-- Register & Login Form End -->

                        </div>
                    </div>
                </div>
                <!-- Register & Login Wrapper End -->

            </div>
        </div>
        <!-- Register & Login End -->

    <Footer/>
</template>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Banner from '@/components/Banner.vue';
import axios from 'axios';
import Swal from 'sweetalert2';
export default{
    name:"Register",
    components:{
        Header,Footer,Banner
    },
    data() {
        return {
            details: {},
            loading: false,
            has_sponsor: false,
        }
    },
    methods: {
        selectOption(event) {
            this.details.sponsor = event.target.value
        },
        toggleSponsorStatus() {
            // console.log(event.target)
            this.has_sponsor = !this.has_sponsor
        },
        signup() {
            this.loading = true
            axios.post('https://api.bhubcollege.com/api/v1/student/signup', this.details)
            .then( response => {
                if(response.status) {
                    Swal.fire({
                        title: 'Success',
                        icon: 'success',
                        text: 'Signup Successful. A verification link has been sent to your email '+this.details.email
                    })
                    .then(() => window.location.reload())
                }
            })
            .catch(err => {
                if(err.response) {
                    let message
                    if(err.response.status == 422 || err.response.status == 200 || err.response.status == 401 || err.response.status == 404) {
                        if(err.response.data.errors) {
                            let errors = err.response.data.errors
                            let errorList = Object.values(errors)
                            errorList.map(msg => {
                                message = msg
                            })
                        }
                        Swal.fire({
                            icon: 'error',
                            text: err.response.data.message || message
                        })
                    }
                }                
            })
            .finally(() => this.loading = false)
        }
    }
}
</script>